import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  userTaskStatuses,
  checkUserTask,
  downloadUserTask
} from 'entities/userTask';
import { showSnackbar } from 'features/Snackbar';
import { API_URL } from 'shared/lib/request';
import * as api from './api';
import { documentDirectorySearchThunk } from 'entities/documentDirectory';

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const documentsSearchThunk = createAsyncThunk(
  'documents/documentsSearchThunk',
  api.search
);

export const shareDocumentThunk = createAsyncThunk(
  'documents/shareDocumentThunk',
  api.share
);

export const unshareDocumentThunk = createAsyncThunk(
  'documents/unshareDocumentThunk',
  api.unshare
);

export const renameDocumentThunk = createAsyncThunk(
  'documents/renameDocumentThunk',
  async (params, { dispatch }) => {
    try {
      const data = await api.rename(params);
      return data;
    } catch (error) {
      if (error.ErrorCode === 409) {
        dispatch(
          showSnackbar({
            type: 'warning',
            message: `Документ с именем ${params.name} уже существует`
          })
        );
      }

      throw error;
    }
  }
);

export const copyDocumentThunk = createAsyncThunk(
  'documents/copyDocumentThunk',
  api.copy
);

export const moveDocumentThunk = createAsyncThunk(
  'documents/moveDocumentThunk',
  api.move
);

export const deleteDocumentThunk = createAsyncThunk(
  'documents/deleteDocumentThunk',
  api.deleteItem
);

export const restoreDocumentThunk = createAsyncThunk(
  'documents/restoreDocumentThunk',
  async (Ids, { dispatch, getState }) => {
    try {
      return await api.restoreItem({ Ids });
    } catch {
      const ToDirectoryId = getState().documentDirectory.rootDirectories.find(
        (x) => x.name === 'docs'
      ).id;

      dispatch(
        showSnackbar({
          type: 'error',
          message:
            'Невозможно восстановить в исходную папку. Восстановлено в "мои документы'
        })
      );

      return await api.restoreItem({ Ids, ToDirectoryId });
    }
  }
);

export const changeDocumentTypeThunk = createAsyncThunk(
  'documents/changeDocumentTypeThunk',
  api.changeType
);

export const getDocumentInfoThunk = createAsyncThunk(
  'documents/getDocumentInfoThunk',
  api.getDocumentInfo
);

export const convertDocumentThunk = createAsyncThunk(
  'documents/convertDocumentThunk',
  async (params, { dispatch }) => {
    try {
      const { TaskId } = await api.convert(params);
      let maxTryCount = 50;

      const loop = async () => {
        const response = await checkUserTask(TaskId);

        if (response.Status === userTaskStatuses.error) {
          dispatch(
            showSnackbar({
              type: 'error',
              message: `Невозможно выгрузить в формате "${params.type}"`
            })
          );

          return;
        }

        if (response.Status === userTaskStatuses.done) {
          window.open(`${API_URL}/v1/UserTask/Download?Id=${TaskId}`);
          //await downloadUserTask(TaskId);
        } else {
          maxTryCount--;

          if (maxTryCount == 0) {
            dispatch(
              showSnackbar({
                type: 'error',
                message: `Превышен лимит ожидания выполнения задачи по конвертации документа в "${params.type}"`
              })
            );
            return;
          }

          await wait(1000);
          await loop();
        }
      };

      await loop();
    } catch (error) {}
  }
);

export const downloadDocumentThunk = createAsyncThunk(
  'documents/downloadDocumentThunk',
  async ({ id /*, fileName, type*/, uid }, { dispatch }) => {
    try {
      ((params) =>
        (window.location.href = `/api/v1/Documents/Download?${new URLSearchParams(
          Object.fromEntries(
            Object.entries(params).filter((values) => {
              return ![null, undefined].includes(values[1]);
            })
          )
        ).toString()}`))({ id, uid });

      /*
      const result = await api.download({ id, uid });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(new Blob([result], { type }));
      link.download = fileName;
      link.click();
      */
    } catch (error) {
      if ([404, 410].includes(error.status)) {
        dispatch(
          showSnackbar({
            type: 'error',
            message: "файл отсутствует на сервере :'("
          })
        );
      }
    }
  }
);

const initialState = {
  searchResults: [],
  searchResultsStatus: 'initial'
};

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setSearchResults: (state, action) => {
      state.searchResults = action.payload;
    }
  },
  extraReducers: (builder) =>
    builder
      .addCase(documentDirectorySearchThunk.pending, (state, action) => {
        state.searchResultsStatus = 'loading';
      })
      .addCase(documentDirectorySearchThunk.fulfilled, (state, action) => {
        state.searchResultsStatus = 'success';
        state.searchResults = action.payload.Directory.Items.concat(
          action.payload.Document.Items
        );
      })
      .addCase(documentDirectorySearchThunk.rejected, (state, action) => {
        state.searchResultsStatus = 'error';
      })
});

export const documentsReducer = documentsSlice.reducer;

export const { setSearchResults } = documentsSlice.actions;

export const selectDocumentsSearchResults = (state) =>
  state.documents.searchResults;

export const selectSearchResultsStatus = (state) =>
  state.documents.searchResultsStatus;
