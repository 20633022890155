import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  styled,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material';

export const CreateEntityNameDialog = ({
  open,
  defaultValue,
  type,
  onApply,
  onClose
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [value, setValue] = React.useState('');
  const [typeCache, setTypeCache] = React.useState(() => type);

  const setInputNodeRef = React.useCallback((node) => {
    node && node.focus();
  }, []);

  const handleChange = (event) => setValue(event.target.value);

  const handleCreate = () => onApply(value);

  React.useEffect(() => {
    type && setTypeCache(type);
  }, [type]);

  React.useLayoutEffect(() => {
    if (type) {
      setValue('');
    }
  }, [type]);

  React.useLayoutEffect(() => {
    defaultValue && setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: !isMobile ? {} : { margin: 0, width: 'calc(100% - 16px)' }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>
        {meta[typeCache]?.title}
      </DialogTitle>
      <DialogContent dividers sx={{ width: isMobile ? '100%' : 400 }}>
        <TextField
          fullWidth
          inputRef={setInputNodeRef}
          value={value}
          placeholder={meta[typeCache]?.placeholder}
          onChange={handleChange}
          onKeyDown={(event) =>
            event.key === 'Enter' && !!value && onApply(value)
          }
        />
      </DialogContent>
      <DialogActions sx={{ color: 'grey.600', justifyContent: 'center' }}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Отмена
        </Button>
        <CreateButton disabled={!value} onClick={handleCreate}>
          {type === 'rename' ? 'Сохранить' : 'Создать'}
        </CreateButton>
      </DialogActions>
    </Dialog>
  );
};

const meta = {
  rename: {
    title: 'Изменить название',
    placeholder: 'Введите название'
  },

  folder: {
    title: 'Создать папку',
    placeholder: 'Новая папка'
  },
  doc: {
    title: 'Создать документ',
    placeholder: 'Новый документ'
  },
  table: {
    title: 'Создать таблицу',
    placeholder: 'Новая таблица'
  },
  presentation: {
    title: 'Создать презентацию',
    placeholder: 'Новая презентация'
  }
};

const CreateButton = styled((props) => (
  <Button variant="contained" {...props} />
))(({ theme }) => ({
  '&:not(:disabled)': {
    backgroundColor: '#ff6a6a',
    color: '#fff'
  }
}));
