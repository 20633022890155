import * as React from 'react';
import { useDispatch } from 'react-redux';
import {
  useTheme,
  useMediaQuery,
  styled,
  Stack,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  KeyboardArrowLeftOutlined,
  CreateNewFolderOutlined,
  FolderOutlined,
  Close,
  Check
} from '@mui/icons-material';

import { addSubDirectoryThunk } from 'entities/documentDirectory';
import { getItem } from 'entities/documentDirectory/api';
import { LoadingBox } from 'shared/components';

export const DirectoryExplorer = ({ initialDirectoryId, data, onChange }) => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = React.useState(false);
  const [newFolder, setNewFolder] = React.useState(false);

  const fetchDirRef = React.useRef();

  const handleDirMouseDown = (selected) => () =>
    onChange({ ...data, selected });

  const handleDirDoubleClick = (dirId) => () => {
    fetchDirRef.current(dirId);
  };

  const handleNewFolderFormSubmit = async (event) => {
    event.preventDefault();

    const name = event.target.name.value;

    if (!name) return;

    try {
      setLoading(true);

      const response = await dispatch(
        addSubDirectoryThunk({
          Name: name,
          Description: `${name} folder`,
          Order: 100,
          Type: 'Default',
          IconId: null,
          RoleIds: [],
          UserIds: [],
          ParentId: data.current.Id
        })
      ).unwrap();

      setNewFolder(false);
      fetchDirRef.current(response.Id);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchDirRef.current = async (dirId) => {
      setLoading(true);

      try {
        const [current] = await getItem(new URLSearchParams({ id: dirId }));
        onChange({ ...data, current, selected: current });
      } finally {
        setLoading(false);
      }
    };
  });

  React.useEffect(() => {
    fetchDirRef.current(initialDirectoryId);
  }, [initialDirectoryId]);

  if (!data?.current) return null;

  return (
    <LoadingBox loading={loading}>
      <Stack sx={{ pt: isMobile ? 0 : 2 }}>
        {!newFolder && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={1}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.2}
              sx={{ pl: 1 }}
            >
              <IconButton
                disabled={!data.current.ParentId}
                onClick={() => fetchDirRef.current(data.current.ParentId)}
              >
                <KeyboardArrowLeftOutlined />
              </IconButton>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data?.selected?.Id === data?.current?.Id}
                    onChange={() => {
                      if (data?.selected?.Id !== data?.current?.Id) {
                        onChange({ ...data, selected: data.current });
                      }
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: '1.125rem', ml: 0.5 }}>
                    {data?.current?.Name}
                  </Typography>
                }
              />
            </Stack>
            <IconButton onClick={() => setNewFolder(true)}>
              <CreateNewFolderOutlined />
            </IconButton>
          </Stack>
        )}
        {newFolder && (
          <form onSubmit={handleNewFolderFormSubmit}>
            <TextField
              fullWidth
              name="name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row">
                      <IconButton onClick={() => setNewFolder(false)}>
                        <Close sx={{ fontSize: 20 }} />
                      </IconButton>
                      <IconButton type="submit">
                        <Check sx={{ fontSize: 20 }} />
                      </IconButton>
                    </Stack>
                  </InputAdornment>
                )
              }}
            />
          </form>
        )}

        {!data.current.Children?.length && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            sx={{ py: 2 }}
          >
            <Typography sx={{ color: 'text.secondary' }}>
              Папка пуста
            </Typography>
          </Stack>
        )}
        {!!data.current.Children?.length && (
          <List>
            {data.current?.Children?.map((dir) => (
              <StyledListItem
                key={dir.Id}
                className={dir.Id === data?.selected?.Id && 'selected'}
                onMouseDown={handleDirMouseDown(dir)}
                onDoubleClick={handleDirDoubleClick(dir.Id)}
                sx={{ px: isMobile ? 0 : '' }}
              >
                <ListItemIcon sx={{ minWidth: 34 }}>
                  <FolderOutlined
                    sx={
                      dir.Id !== data?.selected?.Id
                        ? null
                        : { color: 'primary.main' }
                    }
                  />
                </ListItemIcon>
                <ListItemText>{dir.Name}</ListItemText>
              </StyledListItem>
            ))}
          </List>
        )}
      </Stack>
    </LoadingBox>
  );
};

const StyledListItem = styled((props) => (
  <ListItem
    sx={(theme) => ({
      '&.selected': {
        backgroundColor: theme.palette.grey[100]
      }
    })}
    {...props}
  />
))({});
