import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Stack, Button } from '@mui/material';
import {
  Add,
  KeyboardArrowDown,
  PersonOutline,
  RssFeedOutlined,
  StarBorderOutlined,
  AccessTimeOutlined,
  WorkOutlineOutlined,
  DeleteOutlined,
  ShareOutlined
} from '@mui/icons-material';

import { Navigation } from 'shared/components';
import { accessType, widgets } from 'shared/constants';
import { selectViewer } from 'entities/auth';
import {
  rootDirectoriesConfig,
  selectRootDirectories,
  selectDirectory
} from 'entities/documentDirectory';
import { CreateMenu } from 'features/createMenu';

const isMySchool = process.env.REACT_APP_PROJECT_TYPE === 'MY_SCHOOL';

export const DirectoryNavigation = () => {
  const location = useLocation();

  const viewer = useSelector(selectViewer);
  const rootDirectories = useSelector(selectRootDirectories);
  const directory = useSelector(selectDirectory);

  const [createMenuProps, setCreateMenuProps] = React.useState({});

  const canCreate =
    !location.pathname.includes('/search') &&
    directory &&
    ([
      rootDirectoriesConfig.docs.type,
      rootDirectoriesConfig.common.type
    ].includes(directory.Type) ||
      (directory.Type == 0 &&
        (directory.AccessType === accessType.Write ||
          directory.AccessType === accessType.Full)));

  const menuFolders = rootDirectories
    .filter((directory) =>
      Object.values(rootDirectoriesConfig).find(
        (item) => item.type === directory.type
      )
    )
    .map((item) => ({
      ...item,
      path: item.path,
      icon: navIcons[item.type]
    }));

  const menu = location.pathname.toLowerCase().includes('/link')
    ? [
        ...menuFolders,
        {
          path: location.pathname,
          icon: navIcons[rootDirectoriesConfig.link.type],
          type: rootDirectoriesConfig.link.type,
          title: 'Прямая ссылка'
        }
      ]
    : menuFolders;

  const handleButtonClick = (event) =>
    setCreateMenuProps({
      anchorEl: event.currentTarget,
      onClose: () => setCreateMenuProps({})
    });

  return (
    <>
      <Navigation
        menu={menu}
        header={
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.3}
            sx={{ height: widgets.pageHeaderHeight, px: 3 }}
          >
            <Button
              disabled={!viewer || !canCreate}
              variant="contained"
              startIcon={<Add />}
              onClick={handleButtonClick}
              sx={{
                backgroundColor:
                  !viewer || !canCreate
                    ? ''
                    : isMySchool
                    ? '#86C80E !important'
                    : '#ff6a6a !important',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
            >
              Создать
            </Button>
            <Button
              disabled={!viewer || !canCreate}
              variant="contained"
              onClick={handleButtonClick}
              sx={{
                ml: '1px !important',
                width: 40,
                minWidth: 40,
                backgroundColor:
                  !viewer || !canCreate
                    ? ''
                    : isMySchool
                    ? '#86C80E !important'
                    : '#ff6a6a !important',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
            >
              <KeyboardArrowDown />
            </Button>
          </Stack>
        }
      />
      <CreateMenu {...createMenuProps} />
    </>
  );
};

const navIcons = {
  [rootDirectoriesConfig.docs.type]: <PersonOutline />,
  [rootDirectoriesConfig.sharedToMe.type]: <RssFeedOutlined />,
  [rootDirectoriesConfig.favorites.type]: <StarBorderOutlined />,
  [rootDirectoriesConfig.recent.type]: <AccessTimeOutlined />,
  [rootDirectoriesConfig.common.type]: <WorkOutlineOutlined />,
  // [rootDirectoriesConfig.inProject.type]: (
  //   <ViewInArOutlined sx={{ color: '#ff4463' }} />
  // ),
  [rootDirectoriesConfig.recycleBin.type]: <DeleteOutlined />,
  [rootDirectoriesConfig.globalRecycleBin.type]: <DeleteOutlined />,
  [rootDirectoriesConfig.sharedAccess.type]: <ShareOutlined />,
  [rootDirectoriesConfig.link.type]: <ShareOutlined />
};
