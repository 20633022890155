import { request } from 'shared/lib/request';

export const search = ({ params, signal }) =>
  request(`v1/DocumentDirectory/Search?${params}`, { signal });

export const getItem = (params) =>
  request(`v1/DocumentDirectory/Get${!params ? '' : `?${params}`}`);

export const deleteItem = (id) =>
  request(`v1/DocumentDirectory/Delete?Id=${id}`, { method: 'DELETE' });

export const restoreItem = (body) =>
  request('v1/DocumentDirectory/Restore', { method: 'POST', body });

export const share = (body) =>
  request('v1/DocumentDirectory/Share', { method: 'POST', body });

export const unshare = (body) =>
  request('v1/DocumentDirectory/UnShare', { method: 'POST', body });

export const rename = (body) =>
  request(
    `v1/DocumentDirectory/Rename?${new URLSearchParams(body).toString()}`
  );

export const move = (body) =>
  request(`v1/DocumentDirectory/Move?${new URLSearchParams(body).toString()}`);

export const copy = ({ rule, ...body }) =>
  request(
    `v1/DocumentDirectory/Copy?${new URLSearchParams({
      rule,
      ...body
    }).toString()}`
  );

export const conflict = (body) =>
  request('v1/DocumentDirectory/Conflict', { method: 'POST', body });

export const addSubDirectory = (body) =>
  request('v1/DocumentDirectory/AddSubDirectory', { method: 'POST', body });

export const archive = (id) => request(`v1/DocumentDirectory/Archive?Id=${id}`);

export const archiveDocs = (body) =>
  request('v1/DocumentDirectory/Archive', { method: 'POST', body });
