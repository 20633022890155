import * as React from 'react';
import {
  useTheme,
  useMediaQuery,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Box
} from '@mui/material';
import { Apps } from '@mui/icons-material';
import { selectSettings } from 'entities/auth';
import { useSelector } from 'react-redux';

import iconDriveSrc from 'shared/svg/icon-drive.svg';
import iconMailSrc from 'shared/svg/icon-mail.svg';
import iconCalendarSrc from 'shared/svg/icon-calendar.svg';
import iconDrawSrc from 'shared/svg/icon-draw.svg';

const isDev = process.env.NODE_ENV === 'development';

const getIconAndName = (name) => {
  if (name === 'Admin') return null;
  if (name === 'Disk') return { label: 'Диск', iconSrc: iconDriveSrc, url: '' };
  if (name === 'Mail') return { label: 'Почта', iconSrc: iconMailSrc, url: '' };
  if (name === 'Calendar')
    return { label: 'Календарь', iconSrc: iconCalendarSrc, url: '' };
  if (name === 'Draw')
    return { label: 'Графика', iconSrc: iconDrawSrc, url: '' };
};

const getDevUrl = (name) => {
  if (name === 'Disk') return 'https://drive-app.r7-office.ru';
  if (name === 'Mail') return 'https://mail-app.r7-office.ru';
  if (name === 'Calendar') return 'https://calendar-app.r7-office.ru';
  if (name === 'Draw') return 'https://draw-app.r7-office.ru';
};

const getMenu = (modules) => {
  const menu = [];
  for (let module of modules.ModulesAccess) {
    const menuItem = getIconAndName(module);
    const url = isDev
      ? getDevUrl(module)
      : modules.ModuleUrls.find((x) => x.Name === module)?.Url;
    if (menuItem && !window.location.href.includes(url)) {
      menuItem.url = url;
      menu.push(menuItem);
    }
  }
  return menu;
};

export const AppsMenu = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = React.useState();
  const handleClose = () => setAnchorEl(null);
  const settings = useSelector(selectSettings);
  const modules = settings.ModuleSettings;
  const menu = modules ? getMenu(modules) : [];

  return (
    menu.length > 0 && (
      <>
        <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
          <Apps />
        </IconButton>
        <Menu
          open={!!anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleClose}
        >
          {menu.map((item) => (
            <MenuItem
              key={item.url}
              component="a"
              href={item.url}
              onClick={handleClose}
              sx={{ minHeight: isMobile ? 40 : '' }}
            >
              <ListItemIcon>
                <Box component="img" src={item.iconSrc} height={24} />
              </ListItemIcon>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  );
};
