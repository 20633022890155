import * as React from 'react';
import { useSelector } from 'react-redux';
import { Stack, LinearProgress, Typography } from '@mui/material';

import { selectViewer } from 'entities/auth';
import { formatBytes } from 'shared/lib/formatBytes';

export const DiskSpaceInfo = () => {
  const [data, setData] = React.useState(null);

  const viewer = useSelector(selectViewer);

  React.useEffect(() => {
    const load = async () => setData(viewer.DataStorageQuota);
    viewer && load();
  }, [viewer]);

  const progressValue =
    data?.UsingQuotaSize >= data?.QuotaSize
      ? 100
      : (data?.UsingQuotaSize * 100) / data?.QuotaSize;

  if (!viewer || !data) return null;

  return (
    <Stack spacing={1}>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: '#D9D9D9'
          },
          '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: '#ED736F'
          }
        }}
      />
      <Typography variant="body2">
        Использовано {formatBytes(data.UsingQuotaSize)} из{' '}
        {formatBytes(data.QuotaSize)}
      </Typography>
    </Stack>
  );
};
